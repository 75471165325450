@import '../../theme/variables';
@import '../../theme/snippets';

.tableWrapper {
  overflow: auto;
  border: 1px solid $secondary-color-03;
  border-radius: 8px;
  border-spacing: 0;
  padding: 0;
  filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.05));
  background-color: white;
}

.noBorderWrapper {
  margin-top: 14px;
  border-spacing: 0;
  padding: 0;
  background-color: white;
}

.container {
  position: relative;
  width: 100%;
  border-spacing: 0;
}

.headerBlock {
  position: relative;
  background-color: $neutral-gray;
  color: $secondary-color-02;
  font-size: 0.88rem;
  line-height: 1.25rem;
  z-index: 2;
}

.actionsHeader {
  display: flex;
  justify-content: flex-end;
  margin-right: 12px;
}

.align {
  &left {
    text-align: left;
  }
  &center {
    text-align: center;
  }
  &right {
    text-align: right;
  }
}

.headerCell {
  padding: 8px 16px;
  white-space: nowrap;

  .sortWrapper {
    height: 24px;
    margin-left: 4px;
    cursor: pointer;
    min-width: 24px;
  }

  span {
    font-weight: 400;
    display: flex;
    align-items: center;
  }
}

.tableCell {
  border-top: 1px solid $secondary-color-03;
  display: table-cell;
  vertical-align: middle;
  height: 56px;
  padding: 0 16px;

  &.limitWidth {
    max-width: 200px;
  }

  &.total {
    background-color: $neutral-gray;

    p {
      font-weight: 500;
      color: $secondary-color-02;
    }
  }

  &left {
    justify-content: flex-start;
    text-align: left;
  }
  &center {
    justify-content: center;
    text-align: center;
  }
  &right {
    justify-content: flex-end;
    text-align: right;
  }

  p {
    margin: 0;
  }

  .span {
    display: flex;
    align-items: center;
    justify-content: stretch;
    max-width: 260px;
    gap: 0.5rem;

    &.unlimitedWidth {
      max-width: 100%;
    }

    p {
      @extend %ellipsis-text;
    }
  }

  .singleText {
  }

  .icon {
    margin-right: 8px;

    &round {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      overflow: hidden;
      min-width: 40px;
    }
    &square {
      height: 40px;
      width: 40px;
      border-radius: 8px;
      overflow: hidden;
      min-width: 40px;
    }
    &icon {
      height: 24px;
      width: 24px;
    }
  }
}

.actions {
  height: 100%;
  min-width: 130px;
  padding: 16px 8px;
  border-top: 1px solid $secondary-color-03;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  span {
    display: flex;
    align-items: center;
    justify-content: right;

    div {
      display: flex;
      align-items: center;
      margin-left: 4px;
      cursor: pointer;
    }
  }
}

.tableRow {
  position: relative;
}

.noBorderCell {
  padding-left: 0;
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $white-disabled;
  z-index: 1;
}

.sellLinkIcon {
  min-width: 20px;
}

.selectActionsContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: .7rem;
  margin-bottom: 5px;
  margin-left: 10px;

  .selectAction {
    cursor: pointer;
    color: $primary-color-00;
    display: flex;
    align-items: center;

    &.selectActionDisabled {
      cursor: default;
      color: $secondary-color-03;
    }
  }
}
