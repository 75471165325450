@import '../../../theme/variables';
@import '../../../theme/snippets';

.item {
  width: 100%;
  height: 48px;
  padding-top: 12px;
  display: flex;
  color: #8c979f;

  p {
    @extend %toLongTextHandling;
    max-width: 92%;
    color: #48555e;
    font-size: 14px;
    margin-left: 8px;


    &:hover {
      -ms-overflow-style: unset; /* IE and Edge */
      scrollbar-width: unset;

    }
  }

  .active {
    @extend %centred-content;
    width: 24px;
    height: 24px;
    background: #6cc4d4;
    border-radius: 4px;
    flex-shrink: 0;
  }

  .check {
    width: 24px;
    height: 24px;
    border: 1px solid;
    border-radius: 4px;
    flex-shrink: 0;
  }

  &:hover {
    cursor: pointer;
  }
}
