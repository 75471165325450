@import '../../theme/variables';

.rangePicker {
  width: 100%;
  display: flex;
  //height: 48px;
  margin-bottom: 1rem;
  border-radius: 8px;
  align-items: flex-end;
  justify-content: space-between;
  gap: 1.5rem;

  .wrapper {
    width: 268px;
    position: relative;
    z-index: 10;

    .picker {
      border: 1px solid $secondary-color-03;
      border-radius: 8px;
      padding-left: 48px;
      width: 100%;
      cursor: pointer;
      height: 48px;

      &:focus {
        outline: none;
        border: 1px solid $primary-color-00;
      }

      &.disabled {
        background-color: $neutral-gray;
      }
    }

    .placeholder {
      position: absolute;
      z-index: 1;
      top: 50%;
      transform: translateY(-50%);
      left: 48px;
      font-size: 16px;
      cursor: pointer;
      color: $secondary-color-02;
    }

    .icons {
      position: absolute;
      width: 24px;
      height: 48px;
      display: flex;
      align-items: center;
      z-index: 1;
      left: 16px;
    }

    .label {
      font-size: 0.88rem;
      font-weight: 500;
    }
  }

  .datepickerCustomWrapperClass {
    input {
      font-size: 1rem;
    }
  }
}
