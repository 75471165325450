@import '../../theme/variables';
@import '../../theme/snippets';

.container {
  width: 100%;
  padding: 19px 40px;
  border-bottom: 1px solid $secondary-color-03;
}

.alignLeft {
  padding-left: 0;
}

.marginBottom {
  margin-bottom: 1.5rem;
}

.tab {
  display: inline;
  margin-right: 32px;
  padding: 20px 0;
  color: $secondary-color-02;
  cursor: pointer;
}

.active {
  color: $primary-color-00;
  border-bottom: 2px solid;
}
