@import '../../../theme/variables';

.container {
  width: 100%;
  height: 62px;
  border-bottom: 1px solid $secondary-color-03;
  padding: 15px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $secondary-color-00;
}



