@import '../../theme/variables';
@import '../../theme/snippets';

.hiddenScroll {
  @extend %hidden-scroll;
}

.formWrapper {
  display: block;
  width: 100%;
  max-width: 90vw;

  &.high {
    margin: 60px 0 120px;
  }

  &.low {
    margin: 1rem 0;
  }

  &.lower-middle {
    margin-top: 2rem;
  }

  &.medium {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  &.middle {
    margin-top: 5rem;
    margin-bottom: 6rem;
  }

  &.none {
    margin-top: 0;
  }

  .content {
    width: 100%;
    padding-bottom: 56px;
    max-width: 90vw;
  }

  .formDescription {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 24px;
    color: $secondary-color-02;
  }

  .formDescriptionDark {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 24px;
    color: $secondary-color-00;
  }

  .formLabel {
    margin-bottom: 1rem;
    font-size: 0.88rem;
    font-weight: 500;
  }

  h2 {
    font-weight: 500;
    font-size: 2rem;
    line-height: 2.5rem;
    margin: 0 0 10px;
    color: $secondary-color-00;
  }

  h3 {
    margin-bottom: 24px;
  }

  h4 {
    color: $secondary-color-00;
    margin: 0 0 8px 0;
  }

  p {
    margin-top: 0;
  }
}

.stretchForm {
  display: grid;
  grid-template-columns: 100%;
  gap: 1rem;
  justify-content: space-between;
  min-height: 60vh;
}

.slimFormWithFooter {
  max-width: 546px;
}

.filledWrapper,
.filledWrapperNoAlign {
  background-color: $neutral-gray;
  height: 100%;
  padding: 40px;
}

.filledWrapper {
  text-align: center;

  h2 ~ p {
    padding: 8px;
  }
}

.buttonWrapper {
  margin: 32px 0 0 0;
  display: flex;
  width: 100%;
  gap: 1.5rem;

  &.stickButtons {
    margin: 0;
  }
}

.verticalButtons {
  flex-direction: column;
  gap: 12px;
}

.alignRight {
  justify-content: flex-end !important;
}

.alignCenter {
  justify-content: center !important;
}

.alignLeft {
  justify-content: flex-start !important;
}

.buttonFooter {
  justify-content: space-between;
  border-top: 2px solid $secondary-color-03;
  padding: 24px 48px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.cancelButton {
  margin-right: 24px;
}

.grid_1 {
  display: grid;
  grid-template-columns: 1fr;
}

.grid_1_1,
.grid_1_1s {
  display: grid;
  grid-gap: 0 1rem;
  grid-template-columns: 1fr 1fr;
  align-items: flex-start;
}

.grid_1_1bottom {
  display: grid;
  grid-gap: 0 1rem;
  grid-template-columns: 1fr 1fr;
  align-items: flex-end;
}

.separator {
  width: 100%;
  height: 1px;
  margin: 24px 0;
  background-color: $secondary-color-03;
}

.grid_1_1_1 {
  @extend %grid_1_1_1;
}

.grid_1_1_1_wide {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr;

  & div:last-child {
    margin-bottom: 40px;
  }
}

.grid_1_1_1_1 {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  margin-bottom: 40px;
}

.grid_1_1_2 {
  @extend %grid_1_1_2;
}

@media (max-width: $under-desktop-width) {
  .grid_1_1_2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .grid_1_1_2 div:last-child {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .grid_1_1_1_wide {
    grid-template-columns: 1fr 1fr;

    & div:last-child {
      margin-bottom: 8px;
    }
  }
}

@media (max-width: $tablets-width) {
  .formWrapper {
    max-width: 95vw;

    &.medium {
      margin-top: 3rem;
      margin-bottom: 3rem;
    }
  }

  .grid_1_1s {
    grid-template-columns: 1fr;
  }
}

@media (max-width: $under-tablet-width) {
  .formWrapper {
    padding: 0 16px;
    max-width: 100vw;

    &.medium {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    &.none {
      padding: 0;
    }
  }

  .filledWrapper,
  .filledWrapperNoAlign {
    width: 100%;
    background-color: unset;
  }

  .slimFormWithFooter {
    max-width: 486px;
  }
}

@media (max-width: $mobiles-width) {
  .formWrapper {
    width: 100%;

    &.middle {
      margin-top: .4rem;
      margin-bottom: .4rem;
    }
  }

  .grid_1_1_1 {
    grid-template-columns: 1fr 1fr;
  }
}
