@import '../../theme/variables';
@import '../../theme/snippets';

.wrapper {
  width: 100%;
  position: relative;
  margin-bottom: 16px;

  .errorAnchor {
    position: relative;
  }

  .errorMessage {
    position: absolute;
    color: $error-bride;
    left: 16px;
    font-size: 0.88rem;
    line-height: 1.25rem;
  }

  div.activeSelect {
    border: 1px solid $primary-color-00;
  }

  .label {
    font-weight: 500;
    color: $secondary-color-00;
    font-size: 14px;
  }

  .select {
    @extend %centred-content;
    min-width: fit-content;
    width: 100%;
    justify-content: flex-start;
    height: 48px;
    border: 1px solid #dfe3e5;
    border-radius: 8px;
    position: relative;
    z-index: 1;
    padding-right: 100px;

    &:hover {
      cursor: pointer;
    }

    .count {
      @extend %centred-content;
      position: absolute;
      color: #48555e;
      font-size: 1rem;
      background-color: #fafafa;
      width: max-content;
      padding: 6px 4px;
      right: 72px;
    }

    .clearContent {
      @extend %centred-content;
      position: absolute;
      right: 48px;
    }

    .image {
      position: absolute;
      top: 12px;
      right: 16px;
    }

    .placeholder {
      color: $secondary-color-02;
      margin-left: 16px;
    }
  }

  .error {
    border: 1px solid red;
  }

  .disabled {
    background-color: $neutral-gray;
  }

  .dropdown {
    @extend %hidden-scroll;
    width: 100%;
    max-height: 280px;
    display: none;
    border-radius: 8px;
    padding-left: 16px;
    position: absolute;
    z-index: 30;
    box-shadow: 0 8px 24px rgba(72, 85, 94, 0.15);
    background-color: white;
  }

  .dropdown::-webkit-scrollbar {
    display: none;
  }

  div.open {
    display: block;

    &.openUp {
      bottom: 52px;
    }
  }
}
