@import '../../theme/variables';
@import '../../theme/snippets';

.container {
  position: relative;
  width: 100%;
}

div.headerStyle {
  height: 48px;
  border-radius: 0;
}

.select {
  width: 100%;
  height: 56px;
  border: 1px solid $secondary-color-03;
  border-radius: 8px;
  padding: 16px;
  font-size: 1rem;
  cursor: pointer;
  color: $secondary-color-02;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background-color: $neutral-white;

  &.noWrapper {
    border: unset;
  }
}

.thinMode {
  height: 48px;
}

.selectOpened {
  border: 1px solid $primary-color-00;
}

.placeholder {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all $small-item-transition;
}

.headerStyleMoved {
  top: 12px;
  font-size: 0.75rem;
}

.placeholderHidden {
  display: none;
}

.placeholderMoved {
  top: 10px;
  font-size: 0.75rem;
}

.placeholderWithSearch {
  left: 45px;
}

.caret {
  position: absolute;
  display: flex;
  align-content: center;
  right: 8px;
}

.itemWrapper {
  @extend %hidden-scroll;
  height: 100%;
  width: fit-content;
  max-height: min(400px, 25vh);
  max-width: 100%;
  min-width: max(100px, 100%);
  border-radius: 8px;
  background-color: $neutral-white;
  z-index: 11;
  position: absolute;
  cursor: pointer;
  overflow: scroll;
  opacity: 0;
  transition: opacity 0.2s;
  right: 0;
}

.itemWrapper::-webkit-scrollbar {
  display: none;
}

.wrapperClosed {
  height: 0;
}

.openDown,
.openUp {
  height: unset;
  box-shadow: 0 8px 24px rgba(72, 85, 94, 0.15);
}

.openDown {
  top: 72px;

  &.isThin {
    top: 52px;
  }
}

.openFarDown {
  top: 92px;

  &.isThin {
    top: 72px;
  }
}

.openUp {
  bottom: 48px;
}

.shiftRight {
  left: 0;
}

.shouldShow {
  opacity: 1;
}

.item {
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;

  p {
    @extend %toLongTextHandling;
    &:hover {
      padding-bottom: 0;
    }
  }

  &:hover {
    background-color: $neutral-gray;
  }
}

.active {
  color: $primary-color-00;
  background-color: $neutral-gray;
}

.hint {
  position: absolute;
  top: 22px;
  max-width: 80%;
  display: flex;
  gap: 0.4rem;

  p {
    @extend %toLongTextHandling;
    position: relative;
  }
}

.searchIcon {
  left: -8px;
  top: -6px;

  &.iconWithLabel {
    top: 0;
  }
}

.value {
  color: $secondary-color-00;
}

.errorWrapper {
  @extend %error-wrapper;
}

.errorShowed {
  border: 1px solid $error-bride;
  transition: all $small-item-transition;
}

.disabled {
  background-color: $neutral-gray;
}

.hintThinOrLabel {
  top: 18px;
}

.hintThinWithLabel {
  top: 12px;
}

.label {
  font-size: 0.88rem;
  font-weight: 500;
}

.dropMenuWrapper {
  display: block;
  cursor: pointer;
  min-width: 120px;
}

.dropMenuContainer {
  height: 24px;
  white-space: nowrap;
  position: relative;
  display: flex;
  justify-content: space-between;

  p {
    margin-right: 2.5rem;
  }
}

.dropMenuOptionsContainer {
  position: relative;
  top: -45px;
  left: -16px;
}

.indent {
  margin-bottom: 16px;
}

.searchInput {
  width: 100%;
  border: unset;
  outline: unset;
  font-size: 1rem;
  color: $secondary-color-00;
  overflow: hidden;
}

.captionContainer,
.optionItem {
  display: flex;
  gap: 0.5rem;
  max-width: 100%;

  &.captionWithIcon {
    max-width: 90%;
  }
}

@media (max-width: $under-tablet-width) {
  .dropMenuWrapper {
    min-width: unset;
  }
}
