@import '../../theme/snippets';
@import '../../theme/variables';

.container {
  @extend %centred-content;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  min-height: calc(100vh - 146px);
  height: 100%;
  padding: 24px;

  div {
    text-align: center;
  }

  h2 {
    margin-bottom: 1rem;
    font-weight: 500;
  }

  .default {
    font-weight: 400;
    color: $secondary-color-02;
    max-width: 432px;
  }

  .large {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $secondary-color-02;
    max-width: 560px;
  }

  .mainIcon {
    position: relative;
    width: 100%;
    height: 80px;
  }

  .lightText {
    color: $secondary-color-02;
    font-weight: 500;
  }
}

.buttons {
  display: flex;
  gap: 1.5rem;
}

.downloadBannerIcons {
  display: none;
}


@media (max-width: $mobiles-width) {
  .buttons {
    flex-direction: column;
    justify-content: space-between;
    width: 88vw;
    height: 112px;
    gap: 0
  }

  .downloadBannerIcons {
    display: flex;
    gap: 19px;
  }
}
