@import '../../theme/snippets';
@import '../../theme/variables';

.container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
}

.MessageBlock {
  @extend %centred-content;
  flex-direction: column;
  width: 472px;
  height: 120px;
  .title {
    color: $secondary-color-00;
    font-size: 24px;
  }
  .description {
    color: $secondary-color-02;
    font-size: 16px;
  }
}
