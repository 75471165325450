@import '../../theme/variables';
@import '../../theme/snippets';

.container {
  height: 80px;
  padding: 16px;
  background-color: $neutral-gray;
  border: 1px solid $secondary-color-03;
  border-radius: 8px;
  display: flex;

  &:hover {
    border: 1px solid $primary-color-00;
  }
}

.iconWrapper {
  @extend %centred-content;
  height: 48px;
  width: 48px;
  background-color: $primary-color-00;
  border-radius: 8px;
}

.infoBlock {
  height: 48px;
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: $secondary-color-00;

  h2 {
    font-size: 23px;
    font-weight: 700;
  }

  p {
    margin: 0;
    font-size: .75rem;
    line-height: 1rem;
  }
}
