@import '../../theme/snippets';

.container {
  display: flex;
}

.errorContainer {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 80vh;

  h1 {
    font-size: 7rem;
    line-height: 7rem;
  }

  h2 {
    font-size: 3rem;
    line-height: 3rem;
  }

  h3 {
    font-size: 1.5rem;
    line-height: 3rem;
  }
}

.mainWrapper {
  @extend %hidden-scroll;
  height: 100vh;
  flex: auto;
}

.mainWrapper::-webkit-scrollbar {
  display: none;
}
