@import '../../theme/variables';
@import '../../theme/snippets';

.mainContainer {
  display: flex;
}

.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
}

.legend {
  align-self: flex-start;
  color: $secondary-color-02;
  font-weight: 500;
}

.buttonsBlock {
  position: relative;
  display: flex;
}

.paginationButton {
  @extend %centred-content;
  height: 32px;
  width: 32px;
  background-color: $neutral-gray;
  color: $secondary-color-00;
  border-radius: 4px;
  margin-left: 4px;
  cursor: pointer;
}

.active {
  background-color: $primary-color-00;
  color: $neutral-white;
}

.disabled {
  cursor: unset;
}

@media screen and (max-width: $mobiles-width) {
  .legend {
    align-self: unset;
    font-size: 0.875rem;
  }

  .container {
    flex-direction: column;
    row-gap: 0.5rem;
  }
}
