@import '../../../theme/variables';
@import '../../../theme/snippets';

.inside {
  @extend %inside-item;
}

div.marginTop {
  margin-top: 8px;
}
