@import '../../theme/variables';
@import '../../theme/snippets';

.container {
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  flex-shrink: 0;

  p {
    color: $secondary-color-02;
    margin: 0 8px;
  }
}

.screen {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  cursor: unset;
}
