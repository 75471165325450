@import '../../theme/variables';
@import '../../theme/snippets';

.outWrapper {
  position: relative;
}

.wrapper {
  position: relative;
  display: flex;
}

.marginBottom {
  margin-bottom: 16px;
}

.marginLeft {
  margin-left: 8px;
}

.charityImageContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.charityTitle {
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: $secondary-color-00;
}

.charityDescription {
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: $secondary-color-02;
}

.child {
  width: fit-content;
}

.container {
  position: relative;
  width: 100%;
  height: 56px;

  .input {
    border: 1px solid $secondary-color-03;
    border-radius: 8px;
    padding: 20px 16px 12px 16px;
    width: 100%;
    height: 56px;
    outline: none;
    font-size: 1rem;
    letter-spacing: 1px;
    color: $secondary-color-00;

    &[type='password'] {
      height: 56px;
      letter-spacing: 0.3rem;
      font-size: 0.5rem;
    }

    &:focus {
      border: 2px solid $primary-color-00;
    }
  }

  .disabled {
    background-color: $neutral-gray;
  }

  .phoneInput {
    padding-left: 48px;
    height: 56px;
  }

  .thin {
    height: 48px;
    padding-top: 16px;
  }

  .thinWithHidePlaceholder {
    padding-top: 12px;
  }

  .invalid {
    border: 1px solid $error-bride;
    padding-right: 40px;

    &:focus {
      border: 2px solid $error-bride;
    }
  }

  .withPrefix {
    padding-left: 32px;
  }

  .placeholderWithPrefix {
    padding-left: 16px;
  }

  .prefix {
    position: absolute;
    pointer-events: none;
    left: 20px;
    top: 50%;
    display: flex;
    align-items: center;
    transform: translateY(-50%);
    color: $secondary-color-00;
    transition: all 0.2s;
  }

  .prefixMowed {
    top: 32px;
  }

  .thinInputPrefixMoved {
    top: 27px;
  }

  .input:focus + .placeholder + .prefix,
  .input:not(:placeholder-shown) + .placeholder + .prefix {
    top: 20px;
  }

  .placeholder {
    position: absolute;
    pointer-events: none;
    color: $secondary-color-02;
    left: 18px;
    top: 50%;
    transform: translateY(-50%);
    transition: all $small-item-transition;
  }

  .phonePlaceholder {
    left: 50px;
  }

  .placeholderMowed {
    top: 12px;
    font-size: 0.75rem;
  }

  .hidePlaceholder {
    display: none;
  }

  p.placeholderMowedThin {
    top: 12px;
    font-size: 0.75rem;
  }

  .icon {
    position: absolute;
    right: 12px;
    cursor: pointer;
    height: 24px;
    top: 50%;
    transform: translateY(-50%);

    &.passwordError {
      right: 42px;
    }
  }
}

.label {
  font-size: 0.88rem;
  font-weight: 500;
}

.errorMessage {
  @extend %error-wrapper;
  margin-bottom: 2px;
}

.warnIcon {
  position: absolute;
  right: 16px;
  top: 24px;
  top: calc(50% - 12px);
  display: flex;
  align-items: center;
}

.checkboxContainer {
  @extend %checkboxContainerCommonStyles;

  &.smallCheckbox {
    input {
      width: 18px;
      height: 18px;
    }
  }
}

.thin {
  height: 48px;
  .placeholderMowed {
    top: 10px;
    font-size: 0.75rem;
  }
}

.indent {
  margin-bottom: 16px;
}
