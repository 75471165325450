@import '../../../theme/variables';
@import '../../../theme/snippets';

.container {
  background-color: $secondary-color-01;
  height: 100vh;
  flex: 0 0 232px;
  width: 232px;
  display: grid;
  grid-template-rows: 68px auto 58px;
  transition: all 0.2s;
}

.collapsedBar {
  flex: 0 0 80px;
  width: 80px;
}

.collapsed {
  visibility: collapse;
}

.logoLink {
  padding: 24px 24px 14px 24px;
}

.links {
  @extend %hidden-scroll;
}

.links::-webkit-scrollbar {
  display: none;
}

.linkContainer {
  position: relative;
  display: flex;
  align-items: center;
  height: 48px;
  padding: 0 28px;

  &:hover {
    background-color: $white-hover;
  }

  span + p {
    white-space: nowrap;
    color: $secondary-color-03;
    position: absolute;
    left: 56px;
  }
}

.activeLinkContainer {
  background-color: $white-hover;

  span + p {
    color: $primary-color-00;
  }
}

.collapseButton {
  padding: 14px 24px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: $white-hover;
  }

  p {
    color: $secondary-color-03;
    position: absolute;
    left: 56px;
  }
}

@media (max-width: $tablets-width) {
  .container {
    flex: 0 0 200px;
    width: 200px;
  }

  .collapsedBar {
    flex: 0 0 80px;
    width: 80px;
  }
}
