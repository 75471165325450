@import '../../../theme/variables';

.container {
  padding: 24px 40px;
}

.upperBlock {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 1rem;
}

.bottomBlock {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  margin-top: 1rem;
}

.salesReportContainer {
  margin-top: 1.5rem;
}

.filtersContainer {
  width: fit-content;
  margin-top: 1rem;
  display: grid;
  gap: 1.5rem;
  grid-template-columns: 18rem 400px 200px minmax(220px, auto);

  .filtersButtonContainer {
    margin-top: 24px;
  }
}

.tableTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0 16px;
  color: $secondary-color-00;
}

.link {
  p {
    color: $primary-color-00;
    font-weight: 500;
  }
}

.salesExportButtonContainer {
  width: 100%;
  display: flex;
  justify-content: end;
  margin-top: 1rem;
}

@media (max-width: $desktop-width) {
  .upperBlock {
    grid-template-columns: 1fr 1fr;
  }

  .bottomBlock {
    grid-template-columns: 1fr;
  }
}
